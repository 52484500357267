<script
    lang="ts"
    setup
>
    type Props = {
        withVanishing?: boolean
        withVanishingSelf?: boolean
        enterDuration?: number
        leaveDuration?: number
    }

    type Emit = {
        (event: 'enter'): void
    }

    const props = withDefaults(defineProps<Props>(), {
        enterDuration: 0.2,
        leaveDuration: 0.16
    })
    const emit = defineEmits<Emit>()

    const beforeEnter = (el) => {
        props.withVanishing && el.classList.add('vanishing')
        props.withVanishingSelf && el.classList.add('vanishing-self')
    }

    const enter = (el, done) => {
        const height = el.clientHeight

        emit('enter')

        el.style.opacity = 0
        el.style.height = '0px'

        animate({
            duration: props.enterDuration,
            timing: animationTiming.makeEaseOut(animationTiming.circ),
            draw(progress) {
                el.style.opacity = progress
                el.style.height = (height * progress) + 'px'
            },
            onComplete() {
                el.style.opacity = null
                el.style.height = null

                props.withVanishing && el.classList.remove('vanishing')
                props.withVanishingSelf && el.classList.remove('vanishing-self')

                done()
            }
        })
    }

    const beforeLeave = (el) => {
        props.withVanishing && el.classList.add('vanishing')
        props.withVanishingSelf && el.classList.add('vanishing-self')
    }

    const leave = (el, done) => {
        const height = el.clientHeight

        animate({
            duration: props.leaveDuration,
            timing: animationTiming.makeEaseOut(animationTiming.quad),
            draw(progress) {
                el.style.opacity = 1 - progress
                el.style.height = (height - (height * progress)) + 'px'
            },
            onComplete() {
                el.style.opacity = null
                el.style.height = null

                props.withVanishing && el.classList.remove('vanishing')
                props.withVanishingSelf && el.classList.remove('vanishing-self')

                done()
            }
        })
    }
</script>

<template>
    <Transition
        :css="false"
        @before-enter="beforeEnter"
        @enter="enter"
        @before-leave="beforeLeave"
        @leave="leave"
    >
        <!-- eslint-disable-next-line vue/require-toggle-inside-transition -->
        <slot/>
    </Transition>
</template>
